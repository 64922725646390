
/***
 * 表单序列化(app)
 *
 */
export function transformRequest(data) {
	const formData = new FormData()
	for (const key of Object.keys(data)) {
		formData.append(key, data[key])
	}
	return formData
}
/***
 * 时间格式化
 *
 */
export function timestampFormat(timestamp) {
	function zeroize(num) {
		return (String(num).length == 1 ? '0' : '') + num;
	}
	timestamp = timestamp.replace(/-/g, "/");
	timestamp = Date.parse(timestamp) / 1000;
	var curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
	var timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

	var curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
	var tmDate = new Date(timestamp * 1000);  // 参数时间戳转换成的日期对象

	var Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
	var H = tmDate.getHours(), i = tmDate.getMinutes(), s = tmDate.getSeconds();

	if (timestampDiff < 60) { // 一分钟以内
		return "刚刚";
	} else if (timestampDiff < 3600) { // 一小时前之内
		return Math.floor(timestampDiff / 60) + "分钟前";
	} else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
		return '今天' + zeroize(H) + ':' + zeroize(i);
	} else {
		var newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
		if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
			return '昨天' + zeroize(H) + ':' + zeroize(i);
		} else {
			return Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
		}
	}
}

// 日期格式化
export function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

export function dateFormat(fmt, date) {
	let ret;
	const opt = {
		"Y+": date.getFullYear().toString(),        // 年
		"m+": (date.getMonth() + 1).toString(),     // 月
		"d+": date.getDate().toString(),            // 日
		"H+": date.getHours().toString(),           // 时
		"M+": date.getMinutes().toString(),         // 分
		"S+": date.getSeconds().toString()          // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (let k in opt) {
		ret = new RegExp("(" + k + ")").exec(fmt);
		if (ret) {
			fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
		};
	};
	return fmt;
}

//解析url问号后面的参数，返回为对象
export function GetRequest(url) {
	var theRequest = new Object();
	if (url.indexOf("?") != -1) {
		var str = url.substr(1);
		let strs = str.split("&");
		for (var i = 0; i < strs.length; i++) {
			theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
		}
	}
	return theRequest;
}

//判断是否在微信浏览器打开
export function isWeixin() {
	var ua = navigator.userAgent.toLowerCase();
	if (ua.match(/MicroMessenger/i) == 'micromessenger') {
		return true;
	} else {
		return false;
	}

}

//base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
function dataURLtoFile(dataurl) {
	var arr = dataurl.split(','),
	  mime = arr[0].match(/:(.*?);/)[1],
	  bstr = atob(arr[1]),
	  n = bstr.length,
	  u8arr = new Uint8Array(n);
	while (n--) {
	  u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], { type: mime });
}
//压缩图片
export function compressImg(file){
	var src;
	var files;
	var fileSize = parseFloat(parseInt(file['size'])/1024/1024).toFixed(2);
	var read = new FileReader();
	read.readAsDataURL(file);
	return new Promise(function(resolve, reject){
	 read.onload = function (e) {
	   var img = new Image();
	   img.src = e.target.result;
	   img.onload = function(){
		 //默认按比例压缩
		 var w = this.width,
		   h = this.height;
		 //生成canvas
		 var canvas = document.createElement('canvas');
		 var ctx = canvas.getContext('2d');
		 var base64;
		 // 创建属性节点
		 canvas.setAttribute("width", w);
		 canvas.setAttribute("height", h);
		 ctx.drawImage(this, 0, 0, w, h);
		 console.log('fileSize----',fileSize);
		 if(fileSize<1){
			//如果图片小于一兆 那么不执行压缩操作
			base64 = canvas.toDataURL('image/jpeg', 1);
		}else if(fileSize>1&&fileSize<2.5){
		   //如果图片大于1M并且小于2M 那么压缩0.5
		   base64 = canvas.toDataURL('image/jpeg', 0.5);
		 }else{
		   //如果图片超过2m 那么压缩0.2
		   base64 = canvas.toDataURL('image/jpeg', 0.4);
		 }
		 // 回调函数返回file的值（将base64编码转成file）
		 files = dataURLtoFile(base64); //如果后台接收类型为base64的话这一步可以省略
		 resolve(files)
	   };
	 };
	})
};

/**
 * 防抖
 * @export
 * @param {function} fn   函数
 * @param {Number} time  比如1000
 * @return {*} 
 */
export function debounceFn(fn, time) {
    let delay = time || 500;
    let timeout = null;
    return function () {
        let th = this;
        let args = arguments;
        if (timeout) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            timeout = null;
            fn.apply(th, args);
        }, delay)
    }
}

/**
 * 节流
 * @export
 * @param {function} fn   函数
 * @param {Number} delay  比如1000
 * @return {*} 
 */
export function throttleFn(func, wait = 500) {
    var previous = 0;
    return function () {
        var now = Date.now();
        var context = this;
        var args = arguments;
        if (now - previous > wait) {
            func.apply(context, args);
            previous = now;
        }
    }
}

export default {
	transformRequest,
	timestampFormat,
	dateFormat,
	GetRequest,
	isWeixin,
	compressImg
}
